import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

/**
 *  Arguments of props:
 *      
 *      title : Modal Title
 *      text_body : The text diaplayed in the modal
 *      show : Boolean variable
 *      on_hide : what function will be called when the modal is closed
 *      is_closeable : Boolean, If true a close button (X) is displayed above
 *      click_action : Function when button is closed
 * 
 */




const AlertModal = (props) => {
    const modal_content = props.content;

    return ( 
        <Modal show={modal_content.show} onHide={modal_content.on_hide}>
            <Modal.Header>
            <Modal.Title>{modal_content.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modal_content.text_body}</Modal.Body>
            <Modal.Footer>
            {modal_content.is_closeable && <Button variant="primary" onClick={modal_content.click_action}>
                {modal_content.btn_text}
            </Button>}
            </Modal.Footer>
        </Modal>
     );
}
 
export default AlertModal;