import React from 'react';
import { Outlet } from "react-router-dom";
import NavigationBar from './page_components/nav_bar';
import Footer from './page_components/footer';
import Cookies from 'js-cookie';
import { useAuth } from './authContext';




const RootPage = () => {
    const { login, logout, cookie_name} = useAuth();

    const initializeUserStatus = ()=>{
        if (Cookies.get(cookie_name) !== undefined){
          login();
        } else{
          logout();
        }
    }

    initializeUserStatus();

    return ( 
        <div className="RootPage">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9" crossOrigin="anonymous"></link>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-HwwvtgBNo3bZJJLYd8oVXjrBZt8cqVSpeBNS5n7C8IVInixGAoxmnlMuBnhbgrkm" crossOrigin="anonymous"></script>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
            <link href="https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500&family=Poppins:wght@500;700&display=swap" rel="stylesheet"></link>
            
            <NavigationBar />
            <Outlet />
            <Footer />
        </div>
     );
}
 
export default RootPage;