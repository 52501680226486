import axios from 'axios';
import Cookies from 'js-cookie';




//const baseAPIURL = "https://iammark.pythonanywhere.com";
const baseAPIURL ="http://localhost:8000/";
//const API_auth_cred = "iammark:09274332926";
const API_auth_cred = "mark:123123123";



const fecthData = async (from_server) => {
    const response = await axios.get(from_server);
    const data = response.data;
    return data
}

const fetchDataAuthenticated = async (api_request_url, query_param) => {
  const request_instance = axios.create({
    baseAPIURL,
    timeout: 10000,
    headers: {
      Authorization: "Basic " + btoa(API_auth_cred)
    },
  });

  const api_responce = await request_instance.get(baseAPIURL + api_request_url + "?" + new URLSearchParams(query_param).toString());
  return api_responce;
}



const getApiResponce = async (api_url, request_content) => {
    const request_url = api_url;
    const reponse = await axios.get(request_url, request_content);
    return reponse.data;
}



const sendDataAuthenticated = async (api_request_url, data)=>{
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const request_instance = axios.create({
    baseAPIURL,
    timeout:10000,
    headers: {
      Authorization: "Basic " + btoa(API_auth_cred)
    },
  });

  const api_response = await request_instance.post(baseAPIURL + api_request_url, data, config);
  return api_response;
}


const updateData = async (api_url , unique_key, data) =>{
  const request_instance = axios.create({
    baseAPIURL,
    timeout: 10000,
    headers: {
      Authorization: "Basic " + btoa(API_auth_cred)
    },
  });
  const api_reponse = await request_instance.put(baseAPIURL + api_url + unique_key + "/?" + new URLSearchParams(data).toString());
  return api_reponse;
}

const deleteData = async (api_url, unique_key)=>{
  const delete_instance = axios.create({
      baseAPIURL,
      timeout: 10000,
      headers: {
      Authorization: "Basic " + btoa(API_auth_cred)
    },
  });

  const api_reponse = await delete_instance.delete(baseAPIURL + api_url + unique_key + "/")
  .then(api_reponse => {
    console.log("in axiosn deleting account with response: " + api_reponse);
  })
  .catch(error => {
    console.log("in axios error with value: " + error);
  })
  return api_reponse;
}









/** New Auths **/
const base_url = "https://marktestserver.pythonanywhere.com/";


const client_api = axios.create({
  baseURL: base_url,
});


const postRequest = async (api_uri, data) => {
  const auth_tk = Cookies.get("tk_val");
  //const auth_tk = "defaulttoken";
  
  const api_request = await client_api.post(api_uri, data, {
    headers: {
      "Content-Type"  : "multipart/form-data",
      Authorization : `Token ${auth_tk}`,
    },
  });
  return api_request;
}




const getRequest = async (api_uri) => {
  const auth_tk = Cookies.get("tk_val");

  const api_request = await client_api.get(api_uri, {
    headers: {
      Authorization: `Token ${auth_tk}`,
    },
  });
  return api_request;
}





const patchRequest = async (api_uri, data) => {
  const auth_tk = Cookies.get("tk_val");
  
  const api_request = await client_api.patch(api_uri, data, {
    headers: {
      "Content-Type"  : "multipart/form-data",
      Authorization : `Token ${auth_tk}`,
    },
  });
  return api_request;
}



const deleteRequest = async (api_uri) => {
  const auth_tk = Cookies.get("tk_val");
  const api_request = await client_api.delete(api_uri, {
    headers: {
      Authorization: `Token ${auth_tk}`,
    },
  });
  return api_request;
}




/**
const postRequest = async (api_uri, data) => {
  //const auth_tk = Cookies.get(tk_val);
  const auth_tk = "defaulttoken";
  
  const api_request = await client_api.post(api_uri, data, {
    headers: {
      "Content-Type"  : "multipart/form-data",
      Authorization : `Token ${auth_tk}`,
    },
  });
  return api_request;
}


const patchRequest = async (api_uri, data) => {
  //const auth_tk = Cookies.get(tk_val);
  const auth_tk = "defaulttoken";
  
  const api_request = await client_api.patch(api_uri, data, {
    headers: {
      "Content-Type"  : "multipart/form-data",
      Authorization : `Token ${auth_tk}`,
    },
  });
  return api_request;
}


const updateRequest = async (api_uri, data) => {
  const auth_tk = Cookies.get(tk_val);
  const api_request = await client_api.put(api_uri, data, {
    headers: {
      Authorization: `Token ${auth_tk}`,
    },
  });
  return api_request;
}


const deleteRequest = async (api_uri) => {
  //const auth_tk = Cookies.get(tk_val);
  const auth_tk = "defaulttoken";
  const api_request = await client_api.delete(api_uri, {
    headers: {
      Authorization: `Token ${auth_tk}`,
    },
  });
  return api_request;
}




**/


export {fecthData, getApiResponce, sendDataAuthenticated, fetchDataAuthenticated, updateData, deleteData, postRequest, getRequest, patchRequest, deleteRequest}
