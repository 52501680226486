import React from 'react';
import './footer.css';
import { Link } from "react-router-dom";

import MainLogo from '../../page_assets/images/main_logo.svg';

const Footer = () => {
    return ( 
        <div className="Footer">
            <div className='container'>
                <div className='row'>
                    <div className="col-md-6" id="footer_col_one">
                        <img src={MainLogo} id="footer_main_logo" alt="main logo"></img>
                        <p id="copy_right_text">© 2022-2023 Copyright: APIWeather</p>
                        <p className="options_section_header">
                            Terms and conditions of sale
                        </p>
                        <Link to="/" className="footer_links">Privacy Policy</Link>
                        <br></br>
                        <Link to="/" className="footer_links">Website terms and conditions</Link>
                        
                    </div>

                    <div className="col-md-3" id="footer_col_two">
                        <p className="options_section_header">
                        Product Collections
                        </p>

                        <Link to="/" className="footer_links">Current and Forecast APIs</Link>
                        <br></br>
                        <Link to="/" className="footer_links">Weather Maps</Link>
                        <br></br>
                        <Link to="/" className="footer_links">Weather Dashboard</Link>
                        <br></br>
                        <Link to="/" className="footer_links">Widgets</Link>
                    </div>

                    <div className="col-md-3" id="footer_col_three">
                        <p className="options_section_header">
                        Technologies
                        </p>
                        
                        <Link to="/" className="footer_links">Our technology</Link>
                        <br></br>
                        <Link to="/" className="footer_links">Accuracy and quality of weather data</Link>
                        <br></br>
                        <Link to="/" className="footer_links">Connect your weather station</Link>
                        <br></br>
                        <Link to="/" className="footer_links">Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Footer;

