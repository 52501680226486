import React from "react";
import "./two_col_section.css";


const TwoColSection = (section_content_prop) => {
    const section_content = section_content_prop.content
    return ( 
        <div className="TwoColSection row">
            <div className="col-md-6">
                <p id="section_title">{section_content.title}</p>
                <div className="d-flex justify-content-center">
                    {section_content.first_col}
                </div>
                
            </div>
            <div className="col-md-6 my-auto">
                <p id="content_text">
                    {section_content.second_col}
                </p>
            </div>
        </div> 
    );
}
 
export default TwoColSection;