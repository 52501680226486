import React from 'react';
import './mini_card.css';

const MiniCard = (content_props) => {
    const contents = content_props.content
    return ( 
        <div className="MiniCard box-shadow">
            <img src={contents.image_icon} className="mini_card_icons" alt='current weather icon'></img>
            <p className='mini_card_text'>
                {contents.card_text}
            </p>
        </div>
     );
}
 
export default MiniCard;