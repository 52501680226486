import React from "react";
import './demo_documentation.css';
import { Link } from "react-router-dom";

const DemoDocumentation = () => {
    return ( 
        <div className="DemoDocumentation container">
            <div className="row">
                <div className="col-md12">
                    <p id="header_title">
                        Demo Documentation
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h4>Frontend</h4>
                    <p className="descriptions">
                        <h5>React Js</h5>
                        This webapp is a single page application created with react-js a popular javascript framework that "lets you build user interfaces out of individual pieces called components. Create your own React components like Thumbnail, LikeButton, and Video. Then combine them into entire screens, pages, and apps." ~react <br></br>.

                        <br></br>
                        <h5>React-Bootstrap & Bootstrap</h5>
                        Bootstrap and React-Bootstrap was used to structure the website, Since Bootstrap have no direct support or not that compatible with react when it comes to creating element that need to be rerendered(e.g, creating modals), I use React Bootstrap for such functionality. In order to have less complexity, Element structure (rows & columns) are handled by Bootstrap.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <br></br>
                    <br></br>
                    <h4>Backend</h4>
                    <p className="descriptions">
                        This web app uses Django web but mostly Rest-Framework to manage api request like signing in, signing out, creating account, handling weather forecast api request and much more to run the app
                    </p>
                </div>
            </div>

            <div className="row">
                <br></br>
                <br></br>
                <br></br>
                <div className="col-md-12">
                    <h5 className="notice_text">
                        Want to work with me? Visit my personal website to know more about me 
                        <Link to="https://iammark.pages.dev"> iammark.pages.dev </Link>
                         or send a message to my inbox velmontemarkjohna@gmail.com
                    </h5>
                </div>
            </div>
        </div>
     );
}
 
export default DemoDocumentation;
