import React from 'react';
import './weather_forecast.css';


import WeatherCoverCloudy from '../../page_assets/images/wether_cover_cloudy.svg'
import HumidityIcon from '../../page_assets/images/humidity_icon.svg';
import WindSpeedIcon from '../../page_assets/images/wind_speed_icon.svg';
import WeatherCard from './subcomponents/weather_card';
import WindDirectionIcon from '../../page_assets/images/wind_direction_icon.svg';
import WindGustIcon from '../../page_assets/images/wind_gust_icon.svg';

import { AreaChart, XAxis, YAxis, Tooltip, Area} from 'recharts';



const getMainValue = (object_data, value_index)=>{
    return object_data[Object.keys(object_data)[value_index]]
}


const getCurrentTemeperature = (forecast_data)=>{
   try{

        let daily_data = forecast_data["daily"]
        let temperature_max_data = daily_data['temperature_2m_max']
        let temperature_min_data = daily_data['temperature_2m_min']
        let temperature_avg = (getMainValue(temperature_min_data, 0) + getMainValue(temperature_max_data, 0)) / 2

        return <span>
            {temperature_avg.toFixed(0)}
        </span>
    }
    catch(err){
        return <span>No Data</span>
    }
}

const createDataPlot = (forecast_data, variable_name) =>{
    let daily_data = forecast_data["daily"][variable_name];
    let data_date_keys = Object.keys(daily_data);
    let data = [];
    let i = 0;
    for(i = 0; i < data_date_keys.length; i++){
        let new_data = {date : data_date_keys[i], value : daily_data[data_date_keys[i]]};
        data.push(new_data);
    }
    
    return(
        <AreaChart width={440} height={240} data={data}>
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#55225D" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
            </defs>

            

            <XAxis dataKey="date"/>

            <YAxis />

            <Tooltip />

            
            <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
    );
}


const getExtraForecast = (forecast_data)=>{
    try{
        if(Object.keys(forecast_data).length > 0){
        let daily_data = forecast_data["daily"];
        const precipitation = getMainValue(daily_data["precipitation_probability_mean"], 0);
        const wind_direction = getMainValue(daily_data['winddirection_10m_dominant'], 0);
        const wind_speed = getMainValue(daily_data['windspeed_10m_max'], 0);
        const wind_gust = getMainValue(daily_data['windgusts_10m_max'], 0);

        return <div>
                <img src={HumidityIcon} className="sub_weather_icon" alt='humidity icon'></img>
                <p className='sub_weather_values'>Precipitation: {precipitation}%</p>
                <br></br>
                <img src={WindDirectionIcon} className="sub_weather_icon" alt='humidity icon'></img>
                <p className='sub_weather_values'>Wind Direction: {wind_direction}°</p>
                <br></br>
                <img src={WindSpeedIcon} className="sub_weather_icon" alt='humidity icon'></img>
                <p className='sub_weather_values'>Wind Speed: {wind_speed}km/h</p>
                <br></br>
                <img src={WindGustIcon} className="sub_weather_icon" alt='humidity icon'></img>
                <p className='sub_weather_values'>Wind Gust: {wind_gust}m/s</p>
            </div>
        }
    }
    catch(err){
        return <div>Cant get Data from the server</div>
    }
}



const WeatherForecastComponent = (weather_forecast) => {
    const forecast_data = weather_forecast.forecast['json_responce_record'];
    const forecast_coord = weather_forecast.forecast["lat_long_coord"];
    
    return ( 
        <div className='WeatheerForecastComponent row'>
            <div className='col-12'>

                <div className='row'>
                    <div className='col-md-9 current_forecast_col'>
                        <p id="page_header">Weather Forecast</p>
                    
                        <img src={WeatherCoverCloudy} id="main_weather_icon" alt="wethaer icon"></img>
                        <p id="location_name_temp">
                            <span>{forecast_data && getCurrentTemeperature(forecast_data)} °C</span>
                            <br></br>
                            <span id="coordinate_display">{forecast_coord}</span>
                        </p>
                    </div>

                    <div className='col-md-3 d-flex justify-content-center extra_forecasts'>
                        {forecast_data && getExtraForecast(forecast_data)}
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-6 d-flex justify-content-center">
                        <div className="chart_container overflow-auto">
                            <p className='graph_title'>Precipitation Probability Mean %</p>
                            {forecast_data && createDataPlot(forecast_data, "precipitation_probability_mean")}
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center">
                        <div className="chart_container overflow-auto">
                        <p className='graph_title'>Windgusts 10m Max</p>
                            {forecast_data && createDataPlot(forecast_data, "windgusts_10m_max")}
                        </div>
                    </div>
                </div>


                <div className='row cards_row'>
                    <div className='col-sm-2'>
                        {forecast_data && <WeatherCard forecast_data = {{
                            forecast : forecast_data, 
                            data_index : 1
                            }}/>}
                    </div>
                    <div className='col-sm-2'>
                        {forecast_data && <WeatherCard forecast_data = {{
                            forecast : forecast_data, 
                            data_index : 2
                            }}/>}
                    </div>
                    <div className='col-sm-2'>
                        {forecast_data && <WeatherCard forecast_data = {{
                            forecast : forecast_data, 
                            data_index : 3
                            }}/>}
                    </div>
                    <div className='col-sm-2'>
                        {forecast_data && <WeatherCard forecast_data = {{
                            forecast : forecast_data, 
                            data_index : 4
                            }}/>}
                    </div>
                    <div className='col-sm-2'>
                        {forecast_data && <WeatherCard forecast_data = {{
                            forecast : forecast_data, 
                            data_index : 5
                            }}/>}
                    </div>
                    <div className='col-sm-2'>
                        {forecast_data && <WeatherCard forecast_data = {{
                            forecast : forecast_data, 
                            data_index : 5
                            }}/>}
                    </div>
                </div>

            </div>
        </div>
     );
}
 
export default WeatherForecastComponent;