import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { postRequest } from "./server_connect";
import Cookies from 'js-cookie';
import './login.css';
import AlertModal from "./page_components/subcomponents/alert_modal";

import { useAuth } from "./authContext";

const LoginPage = () => {
    const { login, cookie_name} = useAuth();
    const [user_email, SetUserEmail] = useState("");
    const [user_password, SetPassword] = useState("");
    const navigate = useNavigate();

    const [login_modal_show, SetLoginModalShow] = useState(false);
    const [login_failed_modal_show, SetLoginFailedModadlShow] = useState(false);
    const [login_failed_message, SetLoginFailedMessage] = useState(false);

    const modalHide = ()=>{
        console.log("");
    }
    
    const closeFailedAlertModal = ()=>{
        SetLoginFailedModadlShow(false);
    }

    const attemptLogin = async (e)=>{
        e.preventDefault();
        SetLoginModalShow(true);
        const data = {
            email : user_email,
            password : user_password
        }
        
        await postRequest("/auth-login/", data)
        .then(login_response=>{
            if (login_response.status === 200){
                Cookies.set(cookie_name, JSON.stringify(login_response.data), { expires: 7 });
                Cookies.set("tk_val", login_response.data.token)
                console.log(login_response.data.token)
                login();
                navigate("/pages/dashboard", {});
            } else if(login_response.status === 269){
                SetLoginFailedModadlShow(true);
                SetLoginFailedMessage("Check your email and password");
            } else{
                SetLoginFailedModadlShow(true);
                SetLoginFailedMessage("Please try again");
            }
        })
        .catch(error=>{
            SetLoginFailedModadlShow(true);
            SetLoginFailedMessage("Please try again");
        })
        SetLoginModalShow(false);
    }

    return (
        <div className="LoginPage container">
            <div className="row">
                <div className="col-md-12">
                    <AlertModal id = "loggin_attempt_modal" content = {{
                                title : "Logging In",
                                text_body : "Please Wait",
                                show : login_modal_show,
                                on_hide : modalHide,
                                is_closeable : false,
                                click_action : null,
                            }} />

                    <AlertModal id = "error_alert_modal" content = {{
                                title : "Login Failed",
                                text_body : login_failed_message,
                                show : login_failed_modal_show,
                                on_hide : modalHide,
                                is_closeable : true,
                                click_action : closeFailedAlertModal,
                                btn_text : "Close",
                            }} />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <form onSubmit={attemptLogin}>
                        <div className="row">
                            <div className="col-md-12">
                                <p id="login_page_header">SignIn</p>
                                <div className="input-group mb-3">
                                    <span className="input-group-text input_placeholder" id="basic-addon1">@</span>
                                    <input 
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        aria-label="Email"
                                        aria-describedby="basic-addon1"
                                        required
                                        onChange={(e)=>SetUserEmail(e.target.value)}
                                     />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text input_placeholder" id="basic-addon1">Password</span>
                                    <input 
                                    type="password" 
                                    className="form-control" 
                                    placeholder="Password" 
                                    aria-label="Password" 
                                    aria-describedby="basic-addon1" 
                                    required
                                    onChange={(e)=>SetPassword(e.target.value)}
                                    />
                                </div>

                                <div className="col-auto">
                                    <button type="submit" className="btn" id="login_btn">Login</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default LoginPage;