import React from 'react';
import ButtonPrimary from './subcomponents/button_class_primary';
import './pricing_cards.css';
import './subcomponents/button_classes.css';

const PricingCards = (card_content_prop) => {
    const card_contents = card_content_prop.contents

    return ( 
        <div className="PricingCards">
            <p id="sub_name">{card_contents.title}</p>
            <p id="sub_description">{card_contents.description}</p>
            <span onClick={card_contents.click_method}>
                <ButtonPrimary btn_settings={{
                    btn_class : "btn_no_end ButtonSecondary",
                    btn_text : card_contents.btn_text,
                }}/>
            </span>
            <ul id="package_list">
                {card_contents.packages.map((package_name)=>{
                        return <li className="package_name">{package_name}</li>
                    }
                )}
            </ul>
        </div>
     );
}
 
export default PricingCards;