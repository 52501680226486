import React from "react";
import "./text_box.css";

const TextBox = (box_content_props) => {
    const box_content = box_content_props.content;
    return ( 
        <pre className="TextBox overflow-scroll">
            {box_content.content}
        </pre>
     );
}
 
export default TextBox;