import React, { useState }from "react";
import { postRequest } from "./server_connect";

import './signup.css';
import { useAuth } from "./authContext";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import AlertModal from "./page_components/subcomponents/alert_modal";


const SignUpPage = () => {
    const [user_name, SetUserName] = useState("");
    const [user_email, SetUserEmail] = useState("");
    const [user_password, SetUserPassword] = useState("");
    const [password_guide_text, SetPasswordGuideText] = useState("Password did not match")
    const [input_valid, setInputIsValid] = useState(false);
    const { login, cookie_name} = useAuth();
    const [is_signing_up, isSigningUp] = useState(false);
    const [signup_failed_modal_show, SetSignUpFailedModalShow] = useState(false);
    const [signup_failed_message, SetSignupFailedMessage] = useState("");

    const navigate = useNavigate();

    const closeSignUpFailedModal = ()=>{
        SetSignUpFailedModalShow(false);
    }

    const validatePasswordSimilarity = (password_repeat)=>{
        if (user_password !== password_repeat){
            SetPasswordGuideText("Password did not matched");
            setInputIsValid(false);
        }else{
            SetPasswordGuideText("Password matched");
            setInputIsValid(true);
        }
    } 



    const createNewAccount = async (e)=>{
        e.preventDefault();
        isSigningUp(true);
        const data = {
            username : user_name,
            email : user_email,
            password : user_password,
        }
        await postRequest('/api-weather/register/', data)
        .then(apiResponce=>{
            if (apiResponce.status === 201){
                loginNewAccount();
            } else if (apiResponce.status === 226){
                isSigningUp(false);
                SetSignupFailedMessage(apiResponce.data["reponce_text"]);
                SetSignUpFailedModalShow(true);
            } else {
                isSigningUp(false);
                SetSignupFailedMessage("Something Went wrong with status code: " + apiResponce);
                SetSignUpFailedModalShow(true);
            }
        })
        .catch(error => {
            isSigningUp(false);
            SetSignupFailedMessage("An error occured while signin-up with " + error);
            SetSignUpFailedModalShow(true);
        })
    }




    const loginNewAccount = async ()=>{
        const data = {
            email : user_email,
            password : user_password,
        }
        

        await postRequest("/auth-login/", data)
        .then(api_response =>{
            if (api_response.status === 200){
                Cookies.set(cookie_name, JSON.stringify(api_response.data), { expires: 7 });
                Cookies.set("tk_val", api_response.data.token);
                login();
                navigate("/pages/dashboard", {});
            }
        })
        .catch(error => {
            isSigningUp(false);
            SetSignupFailedMessage("An error occured while signing in with " + error);
            SetSignUpFailedModalShow(true);
        })
    }


    return ( 
        <div className="SignUpPage container">
            <div className="row">
                <div className="col-md-12">
                    <AlertModal id="signingup_modal" content = {{
                                title : "Signing Up",
                                text_body : "Account will be Signed in after registration",
                                show : is_signing_up,
                                on_hide : null,
                                is_closeable : false,
                                click_action : null,
                                btn_text : "",
                            }} />
                    
                    <AlertModal id="signingup_failed_modal" content = {{
                                title : "Sign-Up Failed",
                                text_body : signup_failed_message,
                                show : signup_failed_modal_show,
                                on_hide : null,
                                is_closeable : true,
                                click_action : closeSignUpFailedModal,
                                btn_text : "Close",
                            }} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <form onSubmit={createNewAccount}>
                        <div className="row">
                            <div className="col-md-12">
                                <p id="signup_page_header">SignUp</p>
                                <div className="input-group mb-3">
                                    <span className="input-group-text input_placeholder" id="basic-addon1">@</span>
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        placeholder="Email" 
                                        aria-label="Email" 
                                        aria-describedby="basic-addon1"
                                        required
                                        onChange={(e)=>SetUserEmail(e.target.value)}
                                     />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text input_placeholder" id="basic-addon1">Username</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Username" 
                                        aria-label="Username" 
                                        aria-describedby="basic-addon1"
                                        required
                                        onChange={(e)=>SetUserName(e.target.value)}
                                    />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text input_placeholder" id="basic-addon1">Password</span>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        placeholder="Password" 
                                        aria-label="Password" 
                                        aria-describedby="basic-addon1"
                                        required
                                        onChange={(e) => SetUserPassword(e.target.value)}
                                     />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text input_placeholder" id="basic-addon1">Repeat</span>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        placeholder="Repeat password" 
                                        aria-label="Password" 
                                        aria-describedby="basic-addon1"
                                        required
                                        onChange={(e) => validatePasswordSimilarity(e.target.value)}
                                     />
                                </div>
                                <p id="pwd_match_guide">{password_guide_text}</p>
                                {is_signing_up && <p>Signing Up</p>}
                                {!is_signing_up && input_valid && <div className="col-auto">
                                    <button type="submit" className="btn" id="signup_btn">SignUp</button>
                                </div>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default SignUpPage;