import React, {useState, useEffect} from 'react';
import PricingCards from './page_components/pricing_cards';
import './pricing_page.css';

import { patchRequest, getRequest } from './server_connect';
import AlertModal from './page_components/subcomponents/alert_modal';
//import { useAuth } from './authContext';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";


const PricingPage = () => {
    //const {cookie_name, isLoggedIn} = useAuth();
    const [user_data, setUserData] = useState(null); 
    const navigate = useNavigate();
    const [alert_modal_text, setAlertModalText] = useState("");
    const [show_alert_modal, setShowAlertModal] = useState(false);
    const [show_update_modal, setUpdateModal] = useState(false);





    const update_subscription = async(package_name)=>{
        setUpdateModal(true)
        const data = {
            user_subscription : package_name
        }

        await patchRequest(`/user-profile/${user_data.id}/`, data)
        .then(api_response=>{
            if(api_response.status === 200){
                setUpdateModal(false);
                setShowAlertModal(true);
                setAlertModalText("Subscription Updated");
            }
        })
        .catch(error=>{
            setUpdateModal(false);
            setShowAlertModal(true);
            setAlertModalText("Error Occured while updating subscription with error: " + error);
        });
    }



    const updateUserSub = (package_name)=>{
        const user_token = Cookies.get("tk_val");
        if(user_token === undefined){
            navigate("/pages/signin", {});
        }else{
            update_subscription(package_name);
        }
    }




    const closeAlertModal = ()=>{
        setShowAlertModal(false);
    }

    const getFreeSub = ()=>{
        updateUserSub("FREE");
    }

    const getStandardSub = ()=>{
        updateUserSub("STANDARD");
    } 
    
    const getProfessionalSub = ()=>{
        updateUserSub("PROFESSIONAL");
    } 

    const getCorporateSub = ()=>{
        updateUserSub("CORPORATE");
    } 


    useEffect(()=>{
        const user_token = Cookies.get("tk_val");
        if(user_token !== undefined){
            getRequest("/user-profile/")
            .then(api_response => {
                if(api_response.status === 200){
                    setUserData(api_response.data[0]);
                }
            }).catch(error=>{
                setShowAlertModal(true);
                setAlertModalText("Error Occured while retrieving user profile information: " + error);
            });
        }
    }, []);


    return ( 
        <div className="PricingPage container">
            <div className='row'>
                <ddiv className="col-md-12">
                    <AlertModal id="signingup_modal" content = {{
                            title : "Notice",
                            text_body : alert_modal_text,
                            show : show_alert_modal,
                            on_hide : null,
                            is_closeable : true,
                            click_action : closeAlertModal,
                            btn_text : "Close",
                        }} />

                    <AlertModal id="signingup_modal" content = {{
                            title : "Updating",
                            text_body : "Updating Subscription",
                            show : show_update_modal,
                            on_hide : null,
                            is_closeable : false,
                            click_action : null,
                            btn_text : "",
                        }} />
                </ddiv>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <p id="pricing_header">
                        Services Pricing
                    </p>
                    <p className="notice_text">This Website is only a Demo website. There are no real money transactions that is processed and requested. There are no payment form added to this demo for security purposes</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 d-flex justify-content-center'>
                    <PricingCards contents={{
                        click_method : getFreeSub,
                        title:"API Free",
                        btn_text: "Get API",
                        description: "Free 10000 API calls daily for non commercial use ",
                        packages: [
                            "10000 API calls daily",
                            "Non commercial use",
                            "Current Forecast",
                            "Hourly Forecast"
                        ]
                    }}/>
                </div>
                <div className='col-md-6 col-md-6 d-flex justify-content-center'>
                <PricingCards contents={{
                        click_method : getStandardSub,
                        title:"API Standard",
                        btn_text: "Subscribe",
                        description: "Free 1000000 API calls daily, Can be use for commercially ",
                        packages: [
                            "1000000 API calls daily",
                            "Commercial use",
                            "Current Forecast",
                            "Hourly Forecast",
                            "Daily Forecast",
                            "Historical Forecast",
                            "Climatic Forecast",
                        ]
                    }}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 col-md-6 d-flex justify-content-center'>
                <PricingCards contents={{
                        click_method : getProfessionalSub,
                        title:"API Professional",
                        btn_text: "Subscribe",
                        description: "Free 5000000 API calls daily, Can be use for commercialy ",
                        packages: [
                            "1000000 API calls daily",
                            "Commercial use",
                            "Current Forecast",
                            "Hourly Forecast",
                            "Daily Forecast",
                            "Historical Forecast",
                            "Climatic Forecast",
                        ]
                    }}/>
                </div>
                <div className='col-md-6 col-md-6 d-flex justify-content-center'>
                <PricingCards contents={{
                        click_method : getCorporateSub,
                        title:"API Corporate",
                        btn_text: "Subscribe",
                        description: "Free 100,000,000 API calls daily, Can be use for commercialy ",
                        packages: [
                            "1000000 API calls daily",
                            "Commercial use",
                            "Current Forecast",
                            "Hourly Forecast",
                            "Daily Forecast",
                            "Historical Forecast",
                            "Climatic Forecast",
                            "Priority"
                        ]
                    }}/>
                </div>
            </div>
        </div>
     );
}
 
export default PricingPage;