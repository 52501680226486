import './App.css';
import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom';


import RootPage from './pages/root_page';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import SignUpPage from './pages/signup';
import PricingPage from './pages/pricing_page';
import DashboardPage from './pages/dashboard';
import DocumentationPage from './pages/documentation';
import DemoDocumentation from './pages/demo_documentation';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootPage />}>
      <Route path='/' element={<HomePage />}/>
      <Route path='/pages/signin' element={<LoginPage />}/>
      <Route path='/pages/signup' element={<SignUpPage />} />
      <Route path='/pages/pricingpage' element={<PricingPage />} />
      <Route path="/pages/dashboard" element={<DashboardPage />} />
      <Route path="/pages/documentaion" element={<DocumentationPage />} />
      <Route path="/pages/demoDocs" element={<DemoDocumentation />} />
    </Route>
  ))



function App() {
  return (
    <div className="App">
        <RouterProvider router={router}/>
    </div>
  );
}

export default App;
