import React from "react";
import './button_classes.css';
import { Link } from "react-router-dom";

const ButtonPrimary = (button_data_props) => {
    const button_settings = button_data_props.btn_settings
    return ( 
        <Link to={button_settings.btn_link}>
            <button
                className={button_settings.btn_class} >
                {button_settings.btn_text}
            </button>
        </Link>
     );
}
 
export default ButtonPrimary;