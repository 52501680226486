import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};


export const cookie_name = "tk_name";


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = (userData) => {
    setUser(userData);
    setIsLoggedIn(true);
  };

  const logout = () => {
    setUser(null);
    setIsLoggedIn(false);
    Cookies.remove(cookie_name);
    Cookies.remove("tk_val");
  };

  const value = {
    user,
    isLoggedIn,
    login,
    logout,
    cookie_name,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
