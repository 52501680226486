import React, {useEffect, useState} from 'react';
import './homepage.css';
import './page_components/subcomponents/button_classes.css';

import { getApiResponce } from "./server_connect";


import TwoColSection from './page_components/subcomponents/two_col_section';
import ButtonPrimary from './page_components/subcomponents/button_class_primary';
import TextBox from './page_components/subcomponents/text_box';
import MiniCard from './page_components/subcomponents/mini_card';

import StickerElemOne from '../page_assets/images/sticker_element_one.svg';
import StickerElemTwo from '../page_assets/images/sticker_element_two.svg';
import StickerElemThree from '../page_assets/images/sticker_element_three.svg';
import StickerElemFour from '../page_assets/images/sticker_element_four.svg';

import CurrentWeatherIcon from '../page_assets/images/current_weather_icon.svg';
import HourlyForecastIcon from '../page_assets/images/hourly_forecast_icon.svg';
import DailyForecastIcon from '../page_assets/images/daily_forecast_icon.svg';
import HistoricalMonthly from '../page_assets/images/historical_monthly_icon.svg';
import ClimaticForecast from '../page_assets/images/climatic_forecast_icon.svg';
import HistoricalForecast from '../page_assets/images/historical_monthly_icon.svg';

import WeatherForecastComponent from './page_components/weather_forecast';
import AlertModal from './page_components/subcomponents/alert_modal';
import Cookies from 'js-cookie';
import { useAuth } from './authContext';



const HomePage = () => {
    const { cookie_name} = useAuth();
    const [coordinates, SetCoordinates] = useState({params : {latitude : "14.6760", longitude: "121.0437"}});
    const [used_coordinates, SetUsedCoordinates] = useState({params : {}});
    const [forecast_data, SetForecastData] = useState();

    const [latitude_val, SetLatitudeVal] = useState("14.6760");
    const [longitude_val, SetLongitudeVal] = useState("121.0437");
    const [query_btn_visible, SetQueryBtnVisible] = useState(true);

    const [show_intro_modal, setShowIntroModal] = useState(true)
    const user_cookie = Cookies.get(cookie_name);

    const GetCurrentForecast = ()=>{
        getApiResponce("https://marktestserver.pythonanywhere.com/getopenmeteodata/", coordinates).then(
            response_data => {
            SetForecastData(response_data);
            SetUsedCoordinates(coordinates);
            console.log("Data was retrieved from the server");
            SetQueryBtnVisible(true);
        }).catch(error=>{
            console.log("Error getting data from server with value: " + error);
            SetQueryBtnVisible(true);
        });
    }

    useEffect(()=>{
        if (Object.keys(used_coordinates.params).length <= 1){
            GetCurrentForecast();
        }
    });


    const QueryNewRequest = (e)=>{
        SetCoordinates({params : {latitude : latitude_val, longitude : longitude_val}});
        e.preventDefault();
        GetCurrentForecast();
        SetQueryBtnVisible(false);
    }

    const closeIntroModal = ()=>{
        setShowIntroModal(false);
    }
    


    return ( 
        <div className="HomePage container">
            <img src={StickerElemOne} alt="sticker element" id="sticker_elem_one"></img>
            <img src={StickerElemTwo} alt="sticker element" id="sticker_elem_two"></img>
            <img src={StickerElemThree} alt="sticker element" className="d-none d-md-block" id="sticker_elem_three"></img>
            <img src={StickerElemFour} alt="sticker element" className="d-none d-md-block" id="sticker_elem_four"></img>

            <div className='row'>
                <div className='col-md-12'>
                    {   user_cookie === undefined &&
                        <AlertModal content = {{
                                title : "Welcome, Please take time to read",
                                text_body : "This demo website, built using React JS and the Python Django Rest Framework, features a functional weather forecast API and an account system. You can sign up for an account and utilize the weather API. For comprehensive website documentation, refer to the DemoDocs page, and for detailed API documentation, visit the Documentation page. To learn more about me, visit my personal website at iammark.pages.dev. Please refrain from entering any sensitive information in any input fields. This message will not appear again once you are signed in",
                                show : show_intro_modal,
                                on_hide : null,
                                is_closeable : true,
                                click_action : closeIntroModal,
                                btn_text : "I Understand",
                            }} />
                    }
                </div>
            </div>
            
            <div className="row section_one">
                <div className="col-12 my-auto">
                    <div className="row">
                        <div className="col-12">
                            <p id="tagline">Fast & Reliable Weather Forecast <br></br>API & Web Services</p>
                            <p id="tagline_vision">Our weather API service provides fast and reliable access to current, <br></br> forecasted, and historical weather data from around the world.</p>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12" id="">
                        <ButtonPrimary btn_settings={{
                            btn_class : "btn_end_right ButtonPrimary action_btns",
                            btn_text : "SignUp",
                            btn_link : "/pages/signup"
                        }}/>

                        <ButtonPrimary btn_settings={{
                            btn_class : "btn_end_left ButtonPrimary action_btns",
                            btn_text : "SignIn",
                            btn_link : "/pages/signin"
                        }}/>
                        </div>
                    </div>
                </div>
            </div>

            {forecast_data && <WeatherForecastComponent forecast={forecast_data}/>}
            
            <div className='row'>
                <div className="col-md-12">
                    <form onSubmit={QueryNewRequest}>
                        <div className='row'>
                            <div className='col-md-5'>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Latitude</span>
                                    <input 
                                        type="number" 
                                        step="0.0000001"
                                        className="form-control" 
                                        placeholder="0.00" 
                                        aria-label="number" 
                                        aria-describedby="basic-addon1"
                                        onChange={(e)=>SetLatitudeVal(String(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Longitude</span>
                                    <input 
                                    type="number" 
                                    step="0.0000001"
                                    className="form-control" 
                                    placeholder="0.00" 
                                    aria-label="number" 
                                    aria-describedby="basic-addon1"
                                    onChange={(e)=>SetLongitudeVal(String(e.target.value))}
                                />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                {
                                    (query_btn_visible && <button type="submit" className="btn" id="login_btn">Query</button>) || (!query_btn_visible && <button className="btn" id="login_btn">Querying</button>)
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        
            <div className="row section_two">
                <div className="col-md-12">
                    <TwoColSection 
                    content = {{
                        first_col: <TextBox content={{
                            content: forecast_data && JSON.stringify(forecast_data).replaceAll("}", "}\n").replaceAll(",", ",\n    ").replaceAll('{', "{\n    "),
                        }}/>,
                        title : "JSON",
                        second_col: "The API can be used to retrieve weather data for any location in the Philippines. The data includes current conditions, forecast, and historical data. The API is easy to use and can be integrated into any website or application. To use the API, simply make a request to the URL with the desired parameters. The response will be a JSON file containing the weather data The following parameters are supported by the API"
                        }}
                    />

                <TwoColSection 
                    content = {{
                        first_col: <TextBox content={{
                            content: '<weather>\n<queryCost>1</queryCost>\n<latitude>51.5064</latitude>\n<longitude>-0.12721</longitude>\n<resolvedAddress>London, England, United Kingdom</resolvedAddress>\n<address>London,UK</address>\n<timezone>Europe/London</timezone>\n<tzoffset>1</tzoffset>\n<description>Similar temperatures continuing with a chance of rain Friday, Monday & Tuesday.</description>\n</weather',
                            
                        }}/>,
                        title : "XML",
                        second_col: "The API can be used to retrieve weather data for any location in the Philippines. The data includes current conditions, forecast, and historical data. The API is easy to use and can be integrated into any website or application. To use the API, simply make a request to the URL with the desired parameters. The response will be a JSON file containing the weather data The following parameters are supported by the API"
                        }}
                    />
                </div>
            </div>


            <div className="row section_three">
                <div className='col-md-12'>
                    <div className="row">
                        <div className="col-sm-2 d-flex justify-content-center">
                            <MiniCard content = {{
                                card_text: "Current Weather",
                                image_icon: CurrentWeatherIcon,
                            }}/>
                        </div>
                        <div className="col-sm-2 d-flex justify-content-center">
                            <MiniCard content = {{
                                card_text: "Hourly Forecast",
                                image_icon: HourlyForecastIcon,
                            }}/>
                        </div>
                        <div className="col-sm-2 d-flex justify-content-center">
                            <MiniCard content = {{
                                card_text: "Daily Forecast",
                                image_icon: DailyForecastIcon,
                            }}/>
                        </div>
                        <div className="col-sm-2 d-flex justify-content-center">
                            <MiniCard content = {{
                                card_text: "Historical Monthly",
                                image_icon: HistoricalMonthly,
                            }}/>
                        </div>
                        <div className="col-sm-2 d-flex justify-content-center">
                            <MiniCard content = {{
                                card_text: "Climatic Forecast",
                                image_icon: ClimaticForecast,
                            }}/>
                        </div>
                        <div className="col-sm-2 d-flex justify-content-center">
                            <MiniCard content = {{
                                card_text: "Historical Forecast",
                                image_icon: HistoricalForecast,
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default HomePage;
