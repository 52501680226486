import React from "react";
import './weather_card.css';

import CardCloudIcon from '../../../page_assets/images/card_cloud_icon.svg';

const getMainValue = (object_data, value_index)=>{
    return object_data[Object.keys(object_data)[value_index]]
}


const initWeatherCardForecast = (forecast_data, forecast_index)=>{
    try{
        const daily_data = forecast_data["daily"]
        let temperature_max_data = daily_data['temperature_2m_max']
        let temperature_min_data = daily_data['temperature_2m_min']
        let temperature_avg = (getMainValue(temperature_min_data, forecast_index) + getMainValue(temperature_max_data, 0)) / 2
        const precipitation = getMainValue(daily_data["precipitation_probability_mean"], forecast_index)

        return <div>
                <img src={CardCloudIcon} className="card_icon" alt="card icon"></img>
                <p className="temperature_val">{temperature_avg.toFixed(2)}°C</p>
                <p className="precipitation_val">{precipitation}%</p>
                <p className="date_value">{Object.keys(temperature_min_data)[forecast_index]}</p>
            </div>
    }catch{
        return <div>
                <img src={CardCloudIcon} className="card_icon" alt="card icon"></img>
                <p className="temperature_val">No data</p>
                <p className="precipitation_val">No data</p>
                <p className="date_value">No data</p>
            </div>
    }

}


const WeatherCard = (weather_data_prop) => {
    const forecast_data = weather_data_prop.forecast_data.forecast
    const forecast_index = weather_data_prop.forecast_data.data_index
    
    return ( 
        <div className="WeatherCard d-none d-md-block align-item-center">
            {initWeatherCardForecast(forecast_data, forecast_index)}
        </div>
    );
}
 
export default WeatherCard;