import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import './dashboard.css';
import './page_components/subcomponents/button_classes.css';

import ButtonPrimary from './page_components/subcomponents/button_class_primary';
import UserIcon from "../page_assets/images/user_icon.svg";
import EmailIcon from "../page_assets/images/email_icon.svg";
import PasswordIcon from "../page_assets/images/password_icon.svg"
import APIKeyIcon from "../page_assets/images/api_key_icon.svg";
import SubscriptionIcon from "../page_assets/images/subscription_icon.svg";
import { useAuth } from './authContext';
import {getRequest, patchRequest, postRequest, deleteRequest} from './server_connect';
import AlertModal from './page_components/subcomponents/alert_modal';
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';


const DashboardPage = () => {
    const {cookie_name, logout} = useAuth();
    const navigate = useNavigate();
    const cookie_content = JSON.parse(Cookies.get(cookie_name));
    const [user_data, setUSerData] = useState({})
    const [user_email, setUserEmail] = useState(cookie_content.user_email);
    const [reloading_data_show, SetReloadingDataShow] = useState(false);
    const [change_email_show, SetChangeEmailShow] = useState(false);
    const [change_password_show, SetChangePasswordShow] = useState(false);
    
    const [new_email, SetNewEmail] = useState(null);

    const [error_modal_text, setErrorModalText] = useState("");
    const [show_error_modal, setShowErrorModal] = useState(""); 

    const [old_password, setOldPassword] = useState(null);
    const [new_password_repeat, setNewPasswordRepeat] = useState(null);
    const [new_password, SetNewPassword] = useState(null);

    const [update_user_data_valid, setUpdateUserDataValid] = useState(true);
    const [cancel_subscription, setCancelSubscription] = useState(false);

    const [show_delete_acount, setShowDeleteAccount] = useState(false);





    useEffect(()=>{
        getRequest("/user-profile/")
        .then(responseData=>{
            setUSerData(responseData.data[0]);
        })
        .catch(error=>{
            setErrorModalText("Error getting user data: " + error);
            setShowErrorModal(true);
        })
    }, [user_email]);





    const copyToken = ()=>{
        navigator.clipboard.writeText(user_data.user_api_token);
    }




    const regenerateAPIToken = async ()=>{
        SetReloadingDataShow(true);
        await postRequest("/update-api-token/", {})
        .then(response=>{
            if(response.status === 200){
                setUSerData(response.data);
                console.log("Token Update");
                console.log(response.data);
                SetReloadingDataShow(false);
            }
        })
        .catch(error=>{
            setErrorModalText("Error getting user data: " + error);
            setShowErrorModal(true);
            SetReloadingDataShow(false);
        });
    }




    const closeModals = ()=>{
        SetChangeEmailShow(false);
        SetChangePasswordShow(false);
        setShowErrorModal(false);
        setShowDeleteAccount(false)
    }
    
    const openChangeEmailModal = ()=>{
        SetChangeEmailShow(true)
    }

    const openChangePasswordModal = ()=>{
        SetChangePasswordShow(true);
    }

    const openDeleteAccountModal = ()=>{
        setShowDeleteAccount(true);
    }


    const cancelSub = (e)=>{
        setCancelSubscription(true);
        updateUserData(e)
    }


    const validateChangePassword = ()=>{
        if(new_password === new_password_repeat){
            setUpdateUserDataValid(true);
            return true;
        } else{
            setUpdateUserDataValid(false);
            return false;
        }
    }





    const updateUserData = async (e)=>{
        e.preventDefault();
        closeModals()
        const update_data = {}

        if(new_email !== null){
            update_data.email = new_email;
            setUpdateUserDataValid(true);  
        }

        if(cancel_subscription === true){
            update_data.user_subscription = "FREE";
        }

        if(new_password !== null && validateChangePassword()){
            update_data.password = new_password;
        } 


        if(old_password === ""){
            // Nothing happens
        }

        
        if (Object.keys(update_data).length >= 1 && update_user_data_valid){
            SetReloadingDataShow(true);

            await patchRequest(`/user-profile/${user_data.id}/`, update_data)
            .then(api_reponse=>{
                closeModals();
                SetReloadingDataShow(false);

                if(api_reponse.status === 200){
                    setUSerData(api_reponse.data)
                    Cookies.set(cookie_name, JSON.stringify(api_reponse.data), { expires: 7 });
                    setUserEmail(api_reponse.data.user_email);
                }
            })
            .catch(error=>{
                closeModals()
                setErrorModalText("Internal Error: " + error);
                setShowErrorModal(true);
            });
        } else {
            SetReloadingDataShow(false)
            closeModals()
            setErrorModalText("Please Check yout input");
            setShowErrorModal(true);
        }

        SetNewEmail(null);
        setOldPassword(null);
        SetNewPassword(null);
    }


    const deleteUser = async ()=>{
        await deleteRequest(`/user-profile/${user_data.id}`)
        .then(api_reponse => {
            if(api_reponse.status === 200){
                navigate("/", {});
                logout();
            } 
        })
        .catch(error =>{
            navigate("/", {});
            logout();
        })
    }

    return (  
        <div className="DashboardPage container">
            <div className='row'>
                <div className='col-md-12'>
                    <AlertModal id = "error_alert_modal" content = {{
                            title : "Error Occured",
                            text_body : error_modal_text,
                            show : show_error_modal,
                            on_hide : null,
                            is_closeable : true,
                            click_action : closeModals,
                            btn_text : "Close",
                        }} />

                    <AlertModal id="signingup_modal" content = {{
                            title : "Reloading Data",
                            text_body : "Please Wait...",
                            show : reloading_data_show,
                            on_hide : null,
                            is_closeable : false,
                            click_action : null,
                            btn_text : "",
                        }} />
                    

                    <Modal show={change_email_show} onHide={closeModals}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Email</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={updateUserData}>
                                <div className="input-group mb-3">
                                    <input 
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        aria-label="Email"
                                        aria-describedby="basic-addon1"
                                        required
                                        onChange={(e)=>(SetNewEmail(e.target.value))}
                                     />
                                </div>
                                <button type="submit" className="btn" id="login_btn">Change</button>
                            </form>
                        </Modal.Body>
                    </Modal>

                    <Modal show={change_password_show} onHide={closeModals}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={updateUserData}>
                                <div className="input-group mb-3">
                                    <input 
                                        type="password"
                                        className="form-control"
                                        placeholder="Old Password"
                                        aria-label="password"
                                        aria-describedby="basic-addon1"
                                        required
                                        onChange={(e)=>(setOldPassword(e.target.value))}
                                     />
                                </div>

                                <div className="input-group mb-3">
                                    <input 
                                        type="password"
                                        className="form-control"
                                        placeholder="New Password"
                                        aria-label="password"
                                        aria-describedby="basic-addon1"
                                        required
                                        onChange={(e)=>(SetNewPassword(e.target.value))}
                                     />
                                </div>

                                <div className="input-group mb-3">
                                    <input 
                                        type="password"
                                        className="form-control"
                                        placeholder="Repeat New Password"
                                        aria-label="password"
                                        aria-describedby="basic-addon1"
                                        required
                                        onChange={(e)=>(setNewPasswordRepeat(e.target.value))}
                                     />
                                </div>

                                <button type="submit" className="btn" id="login_btn">Change</button>
                            </form>
                        </Modal.Body>
                    </Modal>


                    <Modal show={show_delete_acount}>
                        <Modal.Header>
                        <Modal.Title>Delete Account</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete your account?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="primary" onClick={closeModals}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={deleteUser}>
                            Delete
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>




            <div className='row'>
                <div className='col-md-12'>
                    <img src={UserIcon} className='d-inline' id="user_icon" alt="user icon" />
                    <p id='username' className='d-inline' >{user_data && user_data.username}</p>
                </div>
            </div>
            
            <div className='row informations_row'>
                <div className='col-md-4 d-flex justify-content-center align-item-center'>
                    <div>
                        <p className="info_name">Email information</p>
                        <img src={EmailIcon} className="d-inline info_icons" alt="Email icon" />
                        <p className="info_value d-inline">{user_data && user_data.email}</p>
                        <span onClick={openChangeEmailModal}>
                            <ButtonPrimary btn_settings={{
                                btn_class : "btn_no_end_secondary ButtonSecondary width_expand_full mt-2 d-block",
                                btn_text : "Change Email",
                                btn_link : "/pages/dashboard"
                            }}/>
                        </span>
                    </div>
                </div>

                <div className='col-md-4 d-flex justify-content-center align-item-center'>
                    <div>
                        <p className="info_name">Password set</p>
                        <img src={PasswordIcon} className="d-inline info_icons" alt="Email icon" />
                        <p className="info_value d-inline">Password Hidden</p>
                        <span onClick={openChangePasswordModal}>
                            <ButtonPrimary btn_settings={{
                                btn_class : "btn_no_end_secondary ButtonSecondary width_expand_full mt-2 d-block",
                                btn_text : "Change",
                                btn_link : "/pages/dashboard"
                            }}/>
                        </span>
                    </div>
                </div>

                <div className='col-md-4 d-flex justify-content-center align-item-center'>
                    <div>
                        <p className="info_name">API Key</p>
                        <img src={APIKeyIcon} className="d-inline info_icons" alt="Email icon" />
                        <p className="info_value d-inline">{user_data && user_data.user_api_token}</p>
                        <span onClick={copyToken}>
                            <ButtonPrimary btn_settings={{
                                btn_class : "btn_no_end_secondary ButtonSecondary width_expand_full mt-2 d-block",
                                btn_text : "Copy",
                                btn_link : "/pages/dashboard"
                            }}/>
                        </span>
                        <span onClick={regenerateAPIToken}>
                        <ButtonPrimary btn_settings={{
                            btn_class : "btn_no_end_secondary ButtonSecondary width_expand_full mt-2 d-block",
                            btn_text : "Regenerate Key",
                            btn_link : "/pages/dashboard"
                        }}/>
                        </span>
                    </div>
                </div>
            </div>




            <div className='row informations_row_two'>
                <div className='col-md-6 d-flex justify-content-center align-item-center'>
                    <div>
                        <p className="info_name">Subscription Information</p>
                        <img src={SubscriptionIcon} className="d-inline info_icons" alt="Email icon" />
                        <p className="info_value d-inline">Subscription {user_data && user_data.user_subscription}</p>
                        <ButtonPrimary btn_settings={{
                            btn_class : "btn_no_end_secondary ButtonSecondary width_expand_full mt-2 d-block",
                            btn_text : "Change",
                            btn_link : "/pages/pricingpage"
                        }}/>
                        <span onClick={(e)=>(cancelSub(e))}>
                            <ButtonPrimary btn_settings={{
                                btn_class : "btn_no_end_secondary ButtonSecondary width_expand_full mt-2 d-block",
                                btn_text : "Cancel",
                                btn_link : "/pages/dashboard"
                            }}/>
                        </span>
                    </div>
                </div>

                <div className='col-md-6 d-flex justify-content-center align-item-center'>
                    <div>
                        <p className="info_name">Delete Account</p>
                        <img src={EmailIcon} className="d-inline info_icons" alt="Email icon" />
                        <pre className="info_value d-inline">By deleting your account 
                        <br></br>
                        will automatically cancel your <br></br>subscription</pre>
                        <span onClick={openDeleteAccountModal}>
                            <ButtonPrimary btn_settings={{
                                btn_class : "btn_no_end_secondary ButtonSecondary width_expand_full mt-2 d-block",
                                btn_text : "Delete",
                                btn_link : "/pages/dashboard"
                            }}/>
                        </span>
                    </div>
                </div>

            </div>
        </div>
     );
}
 
export default DashboardPage;