import React, { useState } from "react";
import { Link, NavLink  } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MainLogo from '../../page_assets/images/main_logo.svg';
import './nav_bar.css';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../authContext";


const NavigationBar = () => {
    const { isLoggedIn, logout } = useAuth();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const cancelLogOut = () => setShow(false);
    const openLogOutModal = () => setShow(true);

    const continueLogOut = ()=>{
        setShow(false);
        logout();
        navigate("/", {});
    }

    const gotToMyPage = ()=>{
        window.location.href = "https://iammark.pages.dev/"
    }

    return ( 
        <nav className="NavigationBar sticky-top navbar-expand-md navigation_bar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Modal show={show} onHide={cancelLogOut}>
                            <Modal.Header closeButton>
                            <Modal.Title>Logout</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to logout?</Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={cancelLogOut}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={continueLogOut}>
                                LogOut
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
                <div className="row">
                    <div className='col-3 d-flex align-items-center'>
                        <Link to="/">
                            <img className='main_logo' src={MainLogo} alt='main logo' />  
                        </Link>
                    </div>

                    <div className='col-9'>
                        <ul className="navbar-nav nav_link_items d-flex justify-content-end align-items-end">
                            <li className="nav_item d-none d-md-block d-lg-block nav_link_text">
                                <NavLink to="https://iammark.pages.dev" className= "nav-link">
                                    IAmMark
                                </NavLink>
                            </li>

                            <li className="nav_item d-none d-md-block d-lg-block nav_link_text">
                                <NavLink 
                                    to="/pages/documentaion" 
                                    className= "nav-link"
                                >
                                        Documentation
                                    </NavLink>
                            </li>

                            <li className="nav_item d-none d-md-block d-lg-block nav_link_text">
                                <NavLink to="/pages/demoDocs" className="nav-link">DemoDocs</NavLink>
                            </li>

                            {
                                isLoggedIn && <li className="nav_item d-none d-md-block d-lg-block nav_link_text">
                                <NavLink 
                                    to="/pages/dashboard" 
                                    className="nav-link"
                                >
                                        Dashboard
                                </NavLink>
                                </li>
                            }

                            <li className="nav_item d-none d-md-block d-lg-block nav_link_text">
                                <NavLink to="/pages/pricingpage" className="nav-link">Prices</NavLink>
                            </li>

                            {
                                !isLoggedIn && <li className="nav_item d-none d-md-block d-lg-block nav_link_text">
                                <NavLink to="/pages/signin" className="nav-link">Signin</NavLink>
                                </li>
                            }

                            {
                                isLoggedIn && <li className="nav_item d-none d-md-block d-lg-block nav_link_text">
                                <NavLink to="/pages/dashboard" className="nav-link" onClick={openLogOutModal}>SignOut</NavLink>
                                </li>
                            }

                            <li className='d-block d-sm-block d-md-none'>
                                <Dropdown id="dropdown">
                                    <Dropdown.Toggle id="drop_down_btn">
                                        Pages
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu id="drop_down_menu">
                                        <Dropdown.Item>
                                        <Link to="https://iammark.pages.dev/" className="nav-link" onClick={gotToMyPage}>
                                            IamMark
                                            </Link>
                                        </Dropdown.Item>

                                        <Dropdown.Item>
                                        <Link to="/pages/documentaion" className="nav-link" >Documentation</Link>
                                        </Dropdown.Item>

                                        <Dropdown.Item>
                                        <Link to="/pages/demoDocs" className="nav-link">DemoDocs</Link>
                                        </Dropdown.Item>

                                        {
                                            isLoggedIn && <Dropdown.Item>
                                            <Link to="/pages/dashboard" className="nav-link">Dashboard</Link>
                                            </Dropdown.Item>
                                        }
                                        

                                        <Dropdown.Item>
                                        <Link to="/pages/pricingpage" className="nav-link">Prices</Link>
                                        </Dropdown.Item>


                                        {
                                            !isLoggedIn &&  <Dropdown.Item>
                                            <Link to="/pages/signin" className="nav-link">Signin</Link>
                                            </Dropdown.Item>
                                        }

                                        {
                                            isLoggedIn &&  <Dropdown.Item>
                                            <Link to="/pages/dashboard" className="nav-link" onClick={openLogOutModal}>SingOut</Link>
                                            </Dropdown.Item>
                                        }

                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
     );
}
 
export default NavigationBar;

